import React, { lazy, Suspense, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import LoaderComponent from "./components/LoaderComponent/LoaderComponent";
import { AuthUserContext } from "./context/auth";
// import Singleadvertiser from "./containers/Company/SingleAdvertiser";
// import Jornayapagefetch from "./containers/Jornaya/JornayaPageFetch";
const Home = lazy(() => import(`./containers/Home/Home`))
const Jornayapagefetch = lazy(() => import(`./containers/Jornaya/JornayaPageFetch`))
const SingleAdvertiser = lazy(() => import(`./containers/Company/SingleAdvertiser`))
const Payments = lazy(() => import(`./containers/Payments/Payments`))
const Landers = lazy(() => import(`./containers/LanderLeadPage/LanderLeadPage`))
const LanderSingle = lazy(() => import(`./containers/LanderLeadPage/LanderSingle`))
const PauseScreen = lazy(() => import(`./containers/PauseScreen/PauseScreen`))
const Company = lazy(() => import(`./containers/Company/Company`))
const ResetMachineChanges = lazy(() => import(`./containers/ResetMachineChanges/ResetMachineChanges`))
const Snapshots = lazy(() => import(`./containers/Snapshots/Snapshots`))
const NamecheapForward = lazy(() => import(`./containers/NamecheapForward/NamecheapForward`))
const ViewerBLA = lazy(() => import(`./containers/ViewerBLA/ViewerBLA`))
const UserScrub = lazy(() => import(`./containers/ViewerBLA/Scrub`))
const CreateCampaigns = lazy(() => import(`./containers/SendScheduler/CreateCampaign`))
const AllRev = lazy(() => import(`./containers/AllRev/AllRev`))

const BLA = lazy(() => import(`./containers/BLA/BLA`))
const BLATEST = lazy(() => import(`./containers/BLA/BLATEST`))
const D7 = lazy(() => import(`./containers/D7/D7`))
const Auth = lazy(() => import(`./containers/Auth/Auth`))
const ClickCatch = lazy(() => import(`./containers/ClickCatch/ClickCatch`))
const CIDAttach = lazy(() => import(`./containers/CidAttach/CIDAttach`))
const Splitter = lazy(() => import(`./containers/Splitter/Splitter`))
const CIDAttachFiles = lazy(() => import(`./containers/CidAttach/CidAttachFiles`))
const PaymentsNumberChanger = lazy(() => import(`./containers/PaymentsNumberChanger/PaymentsNumberChanger`))
const ConversionHomepage = lazy(() => import(`./containers/ConversionHomePage/ConversionHomepage`))
const Page404 = lazy(() => import(`./containers/Page404/Page404`))
const Balance = lazy(() => import(`./containers/Balance/Balance`))
const MessageFormatter = lazy(() => import(`./containers/MessageFormatter/MessageFormatter`))
const MessageFormatterUsers = lazy(() => import(`./containers/MessageFormatter/UserFormats`))
const UploadPhonesToBlackist = lazy(() => import(`./containers/UploadPhonesToBlackist/UploadPhonesToBlackist`))
const UserTrafficPage = lazy(() => import(`./containers/UserTrafficPage/UserTrafficPage`))
const EditDailyRevenue = lazy(() => import(`./containers/EditDailyRevenue/EditDailyRevenue`))
const ProfitSharers = lazy(() => import(`./containers/ProfitSharers/ProfitSharers`))
const FindLead = lazy(() => import(`./containers/FindLead/FindLead`))
const DownloadRealTime = lazy(() => import(`./containers/DownloadRealTime/DownloadRealTime`))
const CompareOffer = lazy(() => import(`./containers/CompareOffer/CompareOffer`))
const OfferGroupDetail = lazy(() => import(`./containers/CompareOffer/OfferGroupDetail`))
const OptinDownload = lazy(() => import(`./containers/OptinDownload/OptinDownload`))
const OptinDownloadDetails = lazy(() => import(`./containers/OptinDownload/OptinDownloadDetails`))
const HomeSims = lazy(() => import(`./containers/HomeSims/HomeSims`))
const VerificationCodes = lazy(() => import(`./containers/VerificationCodes/VerificationCodes`))
const HomeSimsMini = lazy(() => import(`./containers/HomeSimsMini/HomeSimsMini`))
const LBSender = lazy(() => import(`./containers/LBSender/LBSender`))
const Earnings = lazy(() => import(`./containers/Earnings/Earnings`))
const EarningDetails = lazy(() => import(`./containers/Earnings/EarningDetails`))
const SendScheduler = lazy(() => import(`./containers/SendScheduler/SendScheduler`))
const SendingPhones = lazy(() => import(`./containers/SendingPhones/SendingPhones`))
const SingleConversion = lazy(() => import(`./containers/Conversion/SingleConversion`))
const UserSubIDSendView = lazy(() => import(`./containers/UserSubIDSendView/UserSubIDSendView`))
const DirectStatistics = lazy(() => import(`./containers/DirectStatistics/DirectStatistics`))
const ChangeHelpers = lazy(() => import(`./containers/SendingPhones/ChangeHelpers`))
const BalanceNumberChangers = lazy(() => import(`./containers/BalanceNumberChangers/BalanceNumberChangers`))
const NumberChangers = lazy(() => import(`./containers/NumberChangers/NumberChangers`))
const Createnewsubid = lazy(() => import(`./containers/CreateNewSubID/CreateNewSubID`))



function App() {
  const { loading } = useContext(AuthUserContext)
  let pages = <LoaderComponent />
  console.log(loading)
  if (!loading) {
    pages = <div className="App">
      <Switch>
        <Suspense fallback={<LoaderComponent />}>
          <Route path="/" exact={true} component={Home} />
          <Route path="/pause-screen" exact={true} component={PauseScreen} />
          <Route path="/advertisers" exact={true} component={Company} />
          <Route path="/advertisers/:id" exact={true} component={SingleAdvertiser} />
          {/* <Route path="/midredirect" exact={true} component={ClickCatch} /> */}
          <Route path="/namecheap" exact={true} component={NamecheapForward} />
          <Route path="/blacklist" exact={true} component={BLA} />
          <Route path="/blacklist-test" exact={true} component={BLATEST} />
          <Route path="/earnings" exact={true} component={Earnings} />
          <Route path="/sendingphones" exact={true} component={SendingPhones} />
          <Route
            path="/sub-vertical-details"
            exact={true}
            component={EarningDetails}
          />
          <Route path="/sms/homeport-minis" exact={true} component={HomeSims} />
          <Route path="/sms/codes" exact={true} component={VerificationCodes} />
          <Route path="/sms/homeport" exact={true} component={HomeSimsMini} />
          <Route path="/sms/lb" exact={true} component={LBSender} />
          <Route path="/sms/campaigns" exact={true} component={SendScheduler} />
          <Route path="/sms/reset-number-changes" exact={true} component={ResetMachineChanges} />
          <Route path="/my-conversion-details" exact={true} component={UserSubIDSendView} />
          <Route path="/cidattach" exact={true} component={CIDAttach} />
          <Route path="/splitter" exact={true} component={Splitter} />
          <Route path="/cidattach/files" exact={true} component={CIDAttachFiles} />
          <Route
            path="/sms/campaigns/create"
            exact={true}
            component={CreateCampaigns}
          />

          <Route
            path="/conversion-tracker"
            exact={true}
            component={ConversionHomepage}
          />
          <Route
            path="/conversion-details"
            exact={true}
            component={SingleConversion}
          />
          <Route path="/myscrubs" exact={true} component={ViewerBLA} />
          <Route path="/new-scrub" exact={true} component={UserScrub} />
          <Route path="/find-lead" exact={true} component={FindLead} />
          <Route
            path="/revenue-edit/:id"
            exact={true}
            component={EditDailyRevenue}
          />
          <Route
            path="/offergroup/:id"
            exact={true}
            component={OfferGroupDetail}
          />
          <Route path="/profit-sharers" exact={true} component={ProfitSharers} />
          <Route path="/subid-create" exact={true} component={Createnewsubid} />
          <Route path="/statistics" exact={true} component={UserTrafficPage} />
          <Route path="/my-statistics" exact={true} component={DirectStatistics} />
          <Route path="/compare-offers" exact={true} component={CompareOffer} />

          <Route
            path="/real-time-lead"
            exact={true}
            component={DownloadRealTime}
          />

          <Route path="/payments/:id" exact={true} component={Payments} />
          <Route path="/numberchanger/payments/:id" exact={true} component={PaymentsNumberChanger} />
          <Route path="/my-number-changers" exact={true} component={NumberChangers} />
          <Route path="/d7" exact={true} component={D7} />
          <Route
            path="/message-formatter"
            exact={true}
            component={MessageFormatter}
          />
          <Route
            path="/formats"
            exact={true}
            component={MessageFormatterUsers}
          />
 <Route
            path="/super/allrev"
            exact={true}
            component={AllRev}
          />
          <Route
            path="/double-optin-campaigns"
            exact={true}
            component={OptinDownload}
          />
          <Route
            path="/double-optin-group/:id"
            exact={true}
            component={OptinDownloadDetails}
          />
          <Route path="/click-tracker" exact={true} component={ClickCatch} />

          <Route
            path="/sending-numbers"
            exact={true}
            component={ChangeHelpers}
          />
          <Route path="/snapshots-2way-1" exact={true} component={Snapshots} />
          <Route path="/auth" exact={true} component={Auth} />
          <Route path="/404" exact={true} component={Page404} />
          <Route path="/balance/:id" exact={true} component={Balance} />
          <Route path="/numberchanger/balance/:id" exact={true} component={BalanceNumberChangers} />
          <Route
            path="/upload_to_blacklist"
            exact={true}
            component={UploadPhonesToBlackist}
          />
          <Route
            path="/landers"
            exact={true}
            component={Landers}
          />
          <Route
            path="/landers/:traffic"
            exact={true}
            component={LanderSingle}
          />

          <Route path="/legal/leads"

            exact={true}
            component={Jornayapagefetch} />
        </Suspense>
      </Switch>
    </div>

  }
  return pages
}

export default App;
